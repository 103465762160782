import React, { Component, Fragment } from 'react'
import { Card, CardBody, Button } from 'reactstrap';

import envSettings from '../../../globals/envSettings'
import userRoles, { productUserType } from '../../../globals/userRoles'
import digitalProducts from '../../../globals/digitalProducts'
import Modal from '../../Modal/Modal'
import demo from '../../../assets/images/demo.png'
import { withContent, withUserInfo } from '../../../utils/hocs'
import ButtonLti from '../../ButtonLti/ButtonLti'

import PremiumModal from './PremiumModal'
import { SongPalsButton } from 'components/Buttons/SongPalsButton';

class Product extends Component {

  state = {
    showCardInfo: false,
    showModal: false
  }

  togglecardInfo = () => this.setState(prevState => ({
    showCardInfo: !prevState.showCardInfo
  }))

  toggleModal = () => this.setState(prevState => ({
    showModal: !prevState.showModal
  }))

  render() {

    const { content, data, userInfo } = this.props,
    { showCardInfo, showModal } = this.state

    const date = data.endDate ? new Intl.DateTimeFormat(userInfo.language.replace('_', '-'), { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(data.endDate)) : null

    const caseA = (userInfo.role === userRoles.TEACHER || userInfo.role === userRoles.TEACHER_FAKE),
      hasToken = typeof data.token === 'string',
      hasDate = date !== null

    const hasOTButton = !(caseA &&
        (
          (data.productType === digitalProducts.productType.BLINK && (data.activations > 0)) ||
          (data.productType === digitalProducts.productType.PANGEA) ||
          (data.productType === digitalProducts.productType.SONGPALS)
        )
      ),      
      hasCurtain = hasToken && hasDate && data.students >= 0 && data.activations > 0 && data.productType !== digitalProducts.productType.PANGEA,
      hasCBButton = caseA && data.productType === digitalProducts.productType.BLINK && !isNaN(data.activations),
      hasOLBButton = caseA && data.productType === digitalProducts.productType.OLB,
      hasPangeaButton = caseA && data.productType === digitalProducts.productType.PANGEA,
      hasSongPalsButton = [ userRoles.TEACHER, userRoles.TEACHER_FAKE, userRoles.USER_INTERNAL ].includes(userInfo.role) &&
                          data.productType === digitalProducts.productType.SONGPALS

    return (
      <Fragment>
        <Modal
          className="products__modal w-lg-50 w-sm-75"
          isOpen={showModal}
          toggle={() => this.toggleModal()}
          header={data.productName ? data.productName : 'Without Product name'}
        >
          <PremiumModal
            userInfo={userInfo}
            token={data.token}
            productUuid={data.productUuid}
            productType={data.productType}
            date={date}
            toggleModal={this.toggleModal}
            hasCurtain={hasCurtain}
            hasCBButton={hasCBButton}
            hasOTButton={hasOTButton}
            hasOLBButton={hasOLBButton}
            hasPangeaButton={hasPangeaButton}
            student={(data.activations > 0)}
          />
        </Modal>
        <div className="plus__Col position-relative">
          {data.userType === digitalProducts.userType.DEMO &&
            <img className="position-absolute products__demo" src={demo} alt="Oxford Premium" />
          }
          <Card className="mb-4">
            <div className="products__card position-relative w-100 h-100">
              <img
                className="products__card__inside__thumbnail w-100"
                alt={data.productName}
                src={`${envSettings.API_BASEURL}${data.cover}`}
              />
              <div className={`d-flex flex-column p-2 justify-content-center products__card__inside__mask position-absolute w-100 h-100`}>
                <Fragment>
                  {/*((hasOTButton && data.productType !== digitalProducts.productType.BLINK) ||
                    (hasOTButton && !caseA)) && */}
                    {
                    //hasOTButton && data.productType !== digitalProducts.productType.PANGEA &&
                      hasOTButton &&
                      ![digitalProducts.productType.PANGEA, digitalProducts.productType.SONGPALS].includes(data.productType) &&
                      <a href={`${envSettings.API_BASEURL}${data.url}`} target="_blank" rel="noopener noreferrer" >
                        <Button className="my-1 text-uppercase w-100" color="secondary">
                          <span>{content.titledetail.open_title}</span>
                          <span className="ml-1 glyphicon glyphicon-check"></span>
                        </Button>
                      </a>
                    }
                    {hasOTButton && data.productType === digitalProducts.productType.PANGEA &&
                    <a href={content.titledetail.pangea_url_internal} target="_blank" rel="noopener noreferrer" >
                      <Button className="my-1 text-uppercase w-100" color="secondary">
                        <span>{content.titledetail.open_title}</span>
                        <span className="ml-1 glyphicon glyphicon-check"></span>
                      </Button>
                    </a>
                  }
                  { !!hasCBButton
                    && ((userInfo.role === userRoles.TEACHER && userInfo.lti && userInfo.mec)
                    ? <ButtonLti className="my-1 text-uppercase blink_code_students w-100" titleUuid={data.titleUuid}>{ content.titledetail.blink_button }</ButtonLti>
                    : (
                        <Button onClick={() => this.toggleModal()} className="my-1 text-uppercase blink_code_students w-100">
                          { (data.activations > 0)
                            ? <span>{content.titledetail.blink_code_students}</span>
                            : <span>{content.titledetail.blink_code}</span>
                          }
                        </Button>
                      )
                    )
                  }
                  { hasSongPalsButton &&
                    <SongPalsButton
                      url={`${envSettings.API_BASEURL}${data.url}`}
                    />
                  }
                  { hasOLBButton && (data.userType !== productUserType.DEMO) &&
                    <Button
                      onClick={() => this.toggleModal()}
                      className="my-1 text-uppercase blink_code w-100"
                    >
                      <span>{content.titledetail.olb_code}</span>
                    </Button>
                  }
                  { hasPangeaButton && (data.userType !== productUserType.DEMO) &&
                    <Button
                      onClick={() => this.toggleModal()}
                      className="my-1 text-uppercase pangea_code w-100"
                    >
                      <span>{content.titledetail.pangea_code}</span>
                    </Button>
                  }
                </Fragment>
              </div>
            </div>

            <CardBody className="p-0">
              <div className="products__card__inside__legend w-100 position-realtive">
                <div className="py-1 px-2">{data.productName}</div>
                {hasCurtain &&
                  <div className="products__card__inside__legend__expiry py-1 px-2">{content.titledetail.end_date}: {date}</div>
                }
                {hasCurtain &&
                  <div className={`${showCardInfo ? 'products__card__inside__legend__absolute' : ''}`}>
                    <Button onClick={() => this.togglecardInfo()} className="d-flex justify-content-between px-2 py-2 text-left rounded-0 text-uppercase w-100 products__card__inside__legend__expiry__code" >
                      <span>{content.titledetail.students_code}</span>
                      <span className={`h100 glyphicon glyphicon-triangle-${showCardInfo ? 'bottom' : 'top'}`}></span>
                    </Button>

                    {showCardInfo &&
                      <Fragment>
                        <div className="text-uppercase products__card__inside__legend__expiry__showCode py-1 px-2 text-center">
                          <div>
                            <span className="code">{data.token}</span>
                          </div>
                          <a href="/" className="link">{content.titledetail.download_instructions}</a>
                        </div>
                        <div className="py-2 px-2 products__card__inside__legend__expiry__student w-100">
                          {/* <Button onClick={() => this.toggleModal()} > */}
                          <Button onClick={() => console.warn('Desactivate button')} >
                            <span className="mr-2 glyphicon glyphicon-user"></span>
                            <span>{data.students} {content.titledetail.students}</span>
                          </Button>
                        </div>
                      </Fragment>
                    }
                  </div>
                }
              </div>
            </CardBody>
          </Card>
        </div>
      </Fragment>
    )
  }
}

export default withUserInfo(withContent(Product))
