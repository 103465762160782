import React from 'react'
import { Button } from 'reactstrap'
import { withContent } from 'utils/hocs'

export const SongPalsButton = withContent(({
  content,
  url
}) => (
  <Button
    onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
  >
    <span>{content.titledetail.open_songpals}</span>
    <span className="ml-1 glyphicon glyphicon-check"></span>
  </Button>
))