export default {
  userType:
  {
    TEACHER: 'Profesor',
    STUDENT: 'Alumno',
    BOTH: 'Ambos',
    DEMO: 'Demo'
  },
  productType: {
    ONLINE_INTERNAL: 'Interno online',
    ONLINE_EXTERNAL: 'Externo online',
    OLB: 'OLB',
    BLINK: 'Blink',
    PANGEA: 'Pangea',
    SONGPALS: 'SongPals'
  }

}
